enum PosthogEvent {
  AuthLogin = "auth/login",
  AuthLogout = "auth/logout",
  AuthRegister = "auth/register",
  AuthRequestedPasswordReset = "auth/requested-password-reset",
  AuthRequestedEmailVerification = "auth/requested-email-verification",
  AuthEmailVerificationSuccess = "auth/email-verification-success",
  AuthEmailVerificationFailure = "auth/email-verification-failure",
  SolanaWalletLinkingSuccess = "solana/wallet-linking-success",
  SolanaWalletLinkingFailure = "solana/wallet-linking-failure",
}

const AnalyticsEventKeys = {
  CharacterCreation: {
    ClickedCreateCharacter: "character/clicked-create-character",
    CompletedCharacterCreation: "character/completed-character-creation",
  },
};

export { AnalyticsEventKeys };

export default PosthogEvent;
