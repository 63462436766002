import { Box, Flex, Skeleton } from "@chakra-ui/react";

import { Header } from "../layouts/Page.layout";

export default function ChatDetailsPageSuspense() {
  return (
    <Flex flexDirection="column" height="100vh" width="100%" overflow="hidden">
      <Header
        padding={{ base: "0 16px", md: "0 80px" }}
        height="96px"
        borderBottom="0px"
        position="sticky"
        top="0"
        backgroundColor="gray.1100"
        zIndex="3"
      >
        <Box display="flex" alignItems="center">
          <Skeleton height="40px" width="40px" borderRadius="full" mr={3} />
          <Box>
            <Skeleton height="20px" width="150px" mb={1} />
            <Skeleton height="16px" width="100px" />
          </Box>
        </Box>
      </Header>
      <Flex
        flexDirection="column"
        flex="1"
        overflowY="auto"
        position="relative"
      >
        <Box
          display="flex"
          alignItems="center"
          marginTop="32px"
          justifyContent="center"
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Skeleton height="80px" width="80px" borderRadius="full" mb={3} />
            <Skeleton height="20px" width="150px" mb={1} />
            <Skeleton height="16px" width="100px" />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="12px"
          width="100%"
          maxWidth="704px"
          margin="0 auto"
          padding={{ base: "16px", md: "32px" }}
        >
          {[...Array(5)].map((_, index) => (
            <Flex
              key={index}
              flexDirection="column"
              width="100%"
              padding="8px 4px"
            >
              <Flex
                justifyContent={index % 2 === 0 ? "flex-start" : "flex-end"}
                width="100%"
              >
                <Flex
                  flexDirection="column"
                  maxWidth="70%"
                  alignItems={index % 2 === 0 ? "flex-start" : "flex-end"}
                >
                  <Flex
                    alignItems="center"
                    mb={2}
                    flexDirection={index % 2 === 0 ? "row" : "row-reverse"}
                  >
                    <Skeleton
                      height="28px"
                      width="28px"
                      borderRadius="full"
                      mr={index % 2 === 0 ? 2 : 0}
                      ml={index % 2 === 0 ? 0 : 2}
                    />
                    <Skeleton height="16px" width="100px" />
                  </Flex>
                  <Skeleton height="60px" width="250px" borderRadius="md" />
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Box>
      </Flex>
      <Box
        position="sticky"
        bottom="0"
        display="flex"
        justifyContent="center"
        width="100%"
        padding={{ base: "16px", md: "32px" }}
        backgroundColor="gray.1100"
      >
        <Box position="relative" width="100%" maxWidth="660px">
          <Skeleton height="50px" width="100%" borderRadius="full" />
        </Box>
      </Box>
    </Flex>
  );
}
