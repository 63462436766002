import { customAlphabet, nanoid } from "nanoid";
import { v4 as uuidV4 } from "uuid";

import SHORT_ID_LENGTH from "./constants/ShortIdLength";

function nanoId() {
  return nanoid();
}

function truncateId(id: string, lengthToTruncate = SHORT_ID_LENGTH): string {
  return id.length <= lengthToTruncate
    ? id
    : `${id.slice(0, lengthToTruncate)}...`;
}

function shortId(lengthToTruncate = SHORT_ID_LENGTH) {
  return nanoId().slice(0, lengthToTruncate).toLowerCase();
}

const alphanumericOnlyNanoId = customAlphabet(
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
);

function shortIdAlphaNumeric(lengthToTruncate = SHORT_ID_LENGTH) {
  return alphanumericOnlyNanoId(lengthToTruncate);
}

export const ids = {
  nanoId,
  uuidV4,
  truncateId,
  shortId,
  shortIdAlphaNumeric,
};
