import React from "react";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";

import type { Character } from "@charry/models";

import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import CharacterCard from "./CharacterCard";

type CharacterCardListProps = {
  characters: Character[];
  onCharacterClick: (characterId: string) => void;
  horizontal?: boolean;
};

const CharacterCardList: React.FC<CharacterCardListProps> = ({
  characters,
  onCharacterClick,
  horizontal = false,
}) => {
  const isMobile = useIsMobile();

  if (horizontal) {
    return (
      <Flex>
        {characters.map((character, index) => (
          <Box key={`${character.id}-${index}`} minWidth="300px" mr={6}>
            <CharacterCard
              character={character}
              onClick={() => onCharacterClick(character.id)}
            />
          </Box>
        ))}
      </Flex>
    );
  }

  return (
    <Grid
      templateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(3, 1fr)"}
      gap={6}
    >
      {characters.map((character, index) => (
        <GridItem key={`${character.id}-${index}`}>
          <CharacterCard
            character={character}
            onClick={() => onCharacterClick(character.id)}
          />
        </GridItem>
      ))}
    </Grid>
  );
};

export default CharacterCardList;
