import React from "react";
import { Modal, ModalOverlay, Spinner } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useAtomValue } from "jotai";

import type { ConfirmModalProps } from "./Confirm.modal";
import useModal from "~/ui/hooks/useModal.hook";
import { AppState } from "../../jotai/app.jotai";
import { ConfirmModal } from "./Confirm.modal";

export enum ModalTypes {
  Confirm = "Confirm",
}

type ModalProps = {
  [ModalTypes.Confirm]: ConfirmModalProps;
};

export type TModalProps = ModalProps[keyof ModalProps];

export type ModalConfig = {
  type: ModalTypes;
  props: TModalProps;
};

/** ******************************************************************************
 *  Modal
 ****************************************************************************** */

type ModalProviderProps = unknown;

export const ModalProvider: React.FC<ModalProviderProps> = () => {
  const modals = useAtomValue(AppState.modals);
  const open = modals.length > 0;
  const { pop } = useModal();
  /** Render */
  if (!open) return null;

  return (
    <Modal isOpen={true} onClose={() => pop()} size="lg">
      <ModalOverlay backgroundColor="rgba(38,39,43,0.8)" />
      {modals.map((modal: ModalConfig, index: number) => (
        <RenderModal
          key={index}
          modal={modal}
          active={index === modals.length - 1}
        />
      ))}
    </Modal>
  );
};

/** ******************************************************************************
 *  Render Modal
 ****************************************************************************** */

type RenderModalContainerProps = {
  active: boolean;
};

const RenderModalContainer = styled.div<RenderModalContainerProps>`
  position: ${(props) => (props.active ? null : "fixed")};
  top: ${(props) => (props.active ? null : "-10000000px")};
  left: ${(props) => (props.active ? null : "-10000000px")};
`;

type RenderModalProps = {
  modal: ModalConfig;
  active: boolean;
};

const RenderModal: React.FC<RenderModalProps> = ({ modal, active }) => (
  <React.Suspense fallback={<Spinner />}>
    <RenderModalContainer active={active}>
      {(() => {
        const props = modal.props;
        switch (modal.type) {
          case ModalTypes.Confirm:
            return <ConfirmModal {...props} />;

          default:
            return <div />;
        }
      })()}
    </RenderModalContainer>
  </React.Suspense>
);
