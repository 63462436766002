import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import z from "zod";

import { App } from "@charry/console";

const env = import.meta.env;

export const SENTRY_DSN = z
  .string({
    required_error: "VITE_SENTRY_DSN environment variable is required",
  })
  .parse(env.VITE_SENTRY_DSN);

export const NODE_ENV = z
  .string({
    required_error: "VITE_NODE_ENV environment variable is required",
  })
  .parse(env.VITE_NODE_ENV);

export const SENTRY_SAMPLING_RATE = z
  .number()
  .default(0.01)
  .parse(env.VITE_SENTRY_SAMPLING_RATE);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App
      appConfig={{
        runtimeType: () => Promise.resolve("web"),
        isTauri: false,
      }}
    />
  </React.StrictMode>,
);
