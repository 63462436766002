import React from "react";
import { Box, Flex, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { Link as RouterLink } from "react-router-dom";

import useIsMobile from "~/ui/hooks/useIsMobile.hook";

type ChatStarterProps = {
  starter: string;
  onClick: (starter: string) => void;
};

const ChatStarter: React.FC<ChatStarterProps> = ({ starter, onClick }) => {
  const isMobile = useIsMobile();
  const fontSize = useBreakpointValue({ base: "xs", md: "sm" });
  const padding = useBreakpointValue({ base: 2, md: 6 });

  return (
    <Link
      as={RouterLink}
      to="#"
      onClick={(e) => {
        e.preventDefault();
        onClick(starter);
      }}
      _hover={{ textDecoration: "none" }}
      width={"100%"}
    >
      <Box
        padding={padding}
        borderRadius="10px"
        backgroundColor="gray.1000"
        _hover={{ backgroundColor: "gray.800" }}
        transition="background-color 0.2s"
        border="1px solid"
        borderColor="gray.600"
        width={isMobile ? "auto" : "100%"}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Text
            flex={1}
            fontSize={fontSize}
            fontWeight="medium"
            noOfLines={isMobile ? 3 : 2}
            wordBreak="break-word"
          >
            {starter}
          </Text>
          <Box ml={2}>
            <FiChevronRight />
          </Box>
        </Flex>
      </Box>
    </Link>
  );
};

export default ChatStarter;
