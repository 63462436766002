import { Suspense } from "react";
import { useToast } from "@chakra-ui/react";
import { FiCopy } from "react-icons/fi";
import { useCopyToClipboard } from "usehooks-ts";

import type RouteErrorType from "../../types/RouteErrorType";
import { CONSOLE_FULL_VERSION } from "../../main";
import getMessageFromRouteError from "../../utils/getMessageFromRouteError";
import { useAppConfig } from "../hooks/useAppConfig.hook";
import useCharacterList from "../hooks/useCharacterList.hook";
import useUser from "../hooks/useUser.hook";
import HelpButton from "./HelpButton";

type DebugButtonComponentProps = {
  error?: RouteErrorType;
  buttonText?: string;
};

function DebugButtonComponent({
  error,
  buttonText = "Debug Account",
}: DebugButtonComponentProps) {
  const { user } = useUser();
  const toast = useToast();
  const [_, copy] = useCopyToClipboard();
  const { characterList } = useCharacterList();
  const { isTauri } = useAppConfig();
  if (user == null || characterList.length === 0) {
    return null;
  }

  const errorInfo =
    error == null ? "No error info" : getMessageFromRouteError(error);

  return (
    <HelpButton
      text={buttonText}
      Icon={FiCopy}
      onClick={async () => {
        await copy(
          JSON.stringify(
            {
              userId: user._id,
              characterCount: characterList.length,
              errorInfo,
              currentPath: window.location.pathname,
              consoleVersion: isTauri ? CONSOLE_FULL_VERSION : null,
            },
            null,
            2,
          ),
        );
        toast({
          title: "Debug info copied.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }}
    />
  );
}

export default function DebugButton(props: DebugButtonComponentProps) {
  return (
    <Suspense>
      <DebugButtonComponent {...props} />
    </Suspense>
  );
}
