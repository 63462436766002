import z from "zod";

import {
  authData,
  authedServiceRequest,
  authToken,
  serviceRequest,
  serviceResponse,
} from "./BaseService";

export const UserWallet = z.object({
  pubkey: z.string(),
  verifiedAt: z.date(),
});

export const UserSchema = z.object({
  _id: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  passwordHash: z.string(),
  resetToken: z.string().nullable(),
  resetTokenExpiresAt: z.number().nullable(),
  createdAt: z.number().nullable(),
  trialStartedAt: z.number().nullable(),
  trialEndsAt: z.number().nullable(),
  stripeCustomerId: z.string().nullable(),
  balance: z.number(),
  escrowBalance: z.number(),
  emailVerifiedAt: z.date().nullable(),
  emailVerificationToken: z.string().uuid().nullable(),
  emailVerificationTokenRequestedAt: z.date().nullable(),
  emailVerificationRequired: z.boolean(),
  wallets: UserWallet.array().optional(),
  isDisabled: z.boolean(),
  maxInstancesPerWorkerOverride: z.number().nullable(),
  discordId: z.string().optional(),
  discordUsername: z.string().optional(),
  username: z.string().nullable(),
  temperature: z.number().optional(),
  model: z.string().optional(),
});

export const UserMetricsSchema = UserSchema.merge(
  z.object({
    onlineWorkerCount: z.number().optional(),
    totalWorkerCount: z.number().optional(),
  }),
);

const UserWalletSchema = {
  pubkey: { type: String, required: true },
  verifiedAt: { type: Date, required: true },
};

export const UserMongoSchema = {
  _id: { type: String, required: true },
  email: { type: String, required: true },
  firstName: { type: String, required: true },
  lastName: { type: String, required: true },
  passwordHash: { type: String, required: true },
  resetToken: { type: String, required: false },
  resetTokenExpiresAt: { type: Number, required: false },
  createdAt: { type: Number, required: true, default: () => Date.now() },
  trialStartedAt: { type: Number, required: false },
  trialEndsAt: { type: Number, required: false },
  stripeCustomerId: { type: String, required: false },
  balance: { type: Number, required: true },
  escrowBalance: { type: Number, required: true },
  emailVerifiedAt: { type: Date, required: false },
  emailVerificationToken: { type: String, required: false },
  emailVerificationTokenRequestedAt: { type: Date, required: false },
  emailVerificationRequired: { type: Boolean, required: true },
  wallets: { type: [UserWalletSchema], required: true },
  isDisabled: { type: Boolean, required: true, default: false },
  maxInstancesPerWorkerOverride: { type: Number, required: false },
  discordId: { type: String, required: false },
  discordUsername: { type: String, required: false },
  username: { type: String, required: false },
  temperature: { type: Number, required: false },
  model: { type: String, required: false },
};

export const authenticationResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.optional(),
    token: authToken.optional(),
  }),
);

export type User = z.infer<typeof UserSchema>;
export type UserWallet = z.infer<typeof UserWallet>;
export type UserMetrics = z.infer<typeof UserMetricsSchema>;
export type AuthenticationResponse = z.infer<typeof authenticationResponse>;

export type UserService = {
  sendEmailVerificationLink(
    request: SendEmailVerificationLinkRequest,
  ): Promise<SendEmailVerificationLinkResponse>;
  verifyEmailVerificationToken(
    request: VerifyEmailVerificationTokenRequest,
  ): Promise<VerifyEmailVerificationTokenResponse>;
  verifySolanaWalletSignature(
    request: VerifySolanaWalletSignatureRequest,
  ): Promise<VerifySolanaWalletSignatureResponse>;
  unlinkSolanaWallet(
    request: UnlinkSolanaWalletRequest,
  ): Promise<UnlinkSolanaWalletResponse>;
  setTemperature(
    request: SetTemperatureRequest,
  ): Promise<SetTemperatureResponse>;
  setModel(request: SetModelRequest): Promise<SetModelResponse>;
  linkDiscordAccount(
    request: LinkDiscordAccountRequest,
  ): Promise<LinkDiscordAccountResponse>;
  unlinkDiscordAccount(
    request: UnlinkDiscordAccountRequest,
  ): Promise<UnlinkDiscordAccountResponse>;
  login(request: LoginUserRequest): Promise<LoginUserResponse>;
  oAuthLogin(request: OAuthLoginUserRequest): Promise<OAuthLoginUserResponse>;
  byEmail(request: GetUserByEmailRequest): Promise<GetUserByEmailResponse>;
  startReset(
    request: StartResetUserPasswordRequest,
  ): Promise<StartResetUserPasswordResponse>;
  finishReset(
    request: FinishResetUserPasswordRequest,
  ): Promise<FinishResetUserPasswordResponse>;
  get(request: GetUserRequest): Promise<GetUserResponse>;
  getUserWallets(
    request: GetUserWalletsRequest,
  ): Promise<GetUserWalletsResponse>;
  superList(request: SuperListUsersRequest): Promise<SuperListUsersResponse>;
  superImpersonate(
    request: SuperImpersonateUserRequest,
  ): Promise<SuperImpersonateUserResponse>;
  systemUser(): Promise<SystemUserResponse>;
  setUsername(request: SetUsernameRequest): Promise<SetUsernameResponse>;
  getUsername(request: GetUsernameRequest): Promise<GetUsernameResponse>;
};

/** ******************************************************************************
 *  Send Email Verification Link
 ******************************************************************************* */

export const sendEmailVerificationLinkRequest = authedServiceRequest;

export const sendEmailVerificationLinkResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.optional(),
  }),
);

export type SendEmailVerificationLinkRequest = z.infer<
  typeof sendEmailVerificationLinkRequest
>;
export type SendEmailVerificationLinkResponse = z.infer<
  typeof sendEmailVerificationLinkResponse
>;

/** ******************************************************************************
 *  Verify Solana Wallet Signature
 ******************************************************************************* */

export const verifySolanaWalletSignatureParams = z.object({
  signature: z.string(),
  message: z.string(),
  publicKey: z.string(),
});

export const verifySolanaWalletSignatureRequest = authedServiceRequest.merge(
  z.object({
    params: verifySolanaWalletSignatureParams,
  }),
);

export const verifySolanaWalletSignatureResponse = serviceResponse;

export type VerifySolanaWalletSignatureParams = z.infer<
  typeof verifySolanaWalletSignatureParams
>;
export type VerifySolanaWalletSignatureRequest = z.infer<
  typeof verifySolanaWalletSignatureRequest
>;
export type VerifySolanaWalletSignatureResponse = z.infer<
  typeof verifySolanaWalletSignatureResponse
>;

/** ******************************************************************************
 *  Unlink Solana Wallet
 ******************************************************************************* */

export const unlinkSolanaWalletParams = z.object({
  publicKey: z.string(),
});

export const unlinkSolanaWalletRequest = authedServiceRequest.merge(
  z.object({
    params: unlinkSolanaWalletParams,
  }),
);

export const unlinkSolanaWalletResponse = serviceResponse;

export type UnlinkSolanaWalletParams = z.infer<typeof unlinkSolanaWalletParams>;
export type UnlinkSolanaWalletRequest = z.infer<
  typeof unlinkSolanaWalletRequest
>;
export type UnlinkSolanaWalletResponse = z.infer<
  typeof unlinkSolanaWalletResponse
>;

/** ******************************************************************************
 *  Link Discord Account
 ******************************************************************************* */

export const linkDiscordAccountParams = z.object({
  discordId: z.string(),
  discordUsername: z.string(),
});

export const linkDiscordAccountRequest = authedServiceRequest.merge(
  z.object({
    params: linkDiscordAccountParams,
  }),
);

export const linkDiscordAccountResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type LinkDiscordAccountParams = z.infer<typeof linkDiscordAccountParams>;
export type LinkDiscordAccountRequest = z.infer<
  typeof linkDiscordAccountRequest
>;
export type LinkDiscordAccountResponse = z.infer<
  typeof linkDiscordAccountResponse
>;

/** ******************************************************************************
 *  Unlink Discord Account
 ******************************************************************************* */

export const unlinkDiscordAccountRequest = authedServiceRequest;

export const unlinkDiscordAccountResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type UnlinkDiscordAccountRequest = z.infer<
  typeof unlinkDiscordAccountRequest
>;
export type UnlinkDiscordAccountResponse = z.infer<
  typeof unlinkDiscordAccountResponse
>;

/** ******************************************************************************
 *  Set Temperature
 ******************************************************************************* */

export const setTemperatureParams = z.object({
  temperature: z.number(),
});

export const setTemperatureRequest = authedServiceRequest.merge(
  z.object({
    params: setTemperatureParams,
  }),
);

export const setTemperatureResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type SetTemperatureParams = z.infer<typeof setTemperatureParams>;
export type SetTemperatureRequest = z.infer<typeof setTemperatureRequest>;
export type SetTemperatureResponse = z.infer<typeof setTemperatureResponse>;

/** ******************************************************************************
 *  Set Model
 ******************************************************************************* */

export const setModelParams = z.object({
  model: z.string(),
});

export const setModelRequest = authedServiceRequest.merge(
  z.object({
    params: setModelParams,
  }),
);

export const setModelResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type SetModelParams = z.infer<typeof setModelParams>;
export type SetModelRequest = z.infer<typeof setModelRequest>;
export type SetModelResponse = z.infer<typeof setModelResponse>;

/** ******************************************************************************
 *  Verify Email Verification Link
 ******************************************************************************* */

export const verifyEmailVerificationTokenParams = z.object({
  token: z.string(),
});

export const verifyEmailVerificationTokenRequest = serviceRequest.merge(
  z.object({
    params: verifyEmailVerificationTokenParams,
  }),
);

export const verifyEmailVerificationTokenResponse = serviceResponse;

export type VerifyEmailVerificationTokenParams = z.infer<
  typeof verifyEmailVerificationTokenParams
>;
export type VerifyEmailVerificationTokenRequest = z.infer<
  typeof verifyEmailVerificationTokenRequest
>;
export type VerifyEmailVerificationTokenResponse = z.infer<
  typeof verifyEmailVerificationTokenResponse
>;

/** ******************************************************************************
 *  Login User
 ******************************************************************************* */

export const loginUserParams = z.object({
  email: z.string().email(),
  password: z.string(),
});

export const loginUserRequest = serviceRequest.merge(
  z.object({
    params: loginUserParams,
  }),
);

export const loginUserResponse = authenticationResponse;

export type LoginUserParams = z.infer<typeof loginUserParams>;
export type LoginUserRequest = z.infer<typeof loginUserRequest>;
export type LoginUserResponse = z.infer<typeof loginUserResponse>;

/** ******************************************************************************
 *  Login User From Key
 ******************************************************************************* */

export const loginUserFromKeyParams = z.object({
  apiKey: z.string(),
});

export const loginUserFromKeyRequest = serviceRequest.merge(
  z.object({
    params: loginUserFromKeyParams,
  }),
);

export const loginUserFromKeyResponse = authenticationResponse;

export type LoginUserFromKeyParams = z.infer<typeof loginUserFromKeyParams>;
export type LoginUserFromKeyRequest = z.infer<typeof loginUserFromKeyRequest>;
export type LoginUserFromKeyResponse = z.infer<typeof loginUserFromKeyResponse>;

/** ******************************************************************************
 *  Start Reset User Password
 ******************************************************************************* */

export const startResetUserPasswordParams = z.object({
  email: z.string().email(),
});

export const startResetUserPasswordRequest = serviceRequest.merge(
  z.object({
    params: startResetUserPasswordParams,
  }),
);

export const startResetUserPasswordResponse = serviceResponse.merge(
  z.object({
    success: z.boolean(),
  }),
);

export type StartResetUserPasswordParams = z.infer<
  typeof startResetUserPasswordParams
>;
export type StartResetUserPasswordRequest = z.infer<
  typeof startResetUserPasswordRequest
>;
export type StartResetUserPasswordResponse = z.infer<
  typeof startResetUserPasswordResponse
>;

/** ******************************************************************************
 *  Finish Reset User Password
 ******************************************************************************* */

export const finishResetUserPasswordParams = z.object({
  password: z.string(),
  resetToken: z.string(),
});

export const finishResetUserPasswordRequest = serviceRequest.merge(
  z.object({
    params: finishResetUserPasswordParams,
  }),
);

export const finishResetUserPasswordResponse = serviceResponse.merge(
  z.object({
    success: z.boolean(),
  }),
);

export type FinishResetUserPasswordParams = z.infer<
  typeof finishResetUserPasswordParams
>;
export type FinishResetUserPasswordRequest = z.infer<
  typeof finishResetUserPasswordRequest
>;
export type FinishResetUserPasswordResponse = z.infer<
  typeof finishResetUserPasswordResponse
>;

/** ******************************************************************************
 *  Get User
 ******************************************************************************* */

export const getUserParams = z.undefined();

export const getUserRequest = authedServiceRequest.merge(
  z.object({
    params: getUserParams,
  }),
);

export const getUserResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type GetUserParams = z.infer<typeof getUserParams>;
export type GetUserRequest = z.infer<typeof getUserRequest>;
export type GetUserResponse = z.infer<typeof getUserResponse>;

export const getUserWalletsRequest = authedServiceRequest;

export const getUserWalletsResponse = serviceResponse.merge(
  z.object({
    wallets: z.array(UserWallet).optional(),
  }),
);

export type GetUserWalletsRequest = z.infer<typeof getUserWalletsRequest>;
export type GetUserWalletsResponse = z.infer<typeof getUserWalletsResponse>;

/** ******************************************************************************
 *  Set Username
 ******************************************************************************* */

export const setUsernameParams = z.object({
  username: z.string(),
});

export type SetUsernameParams = z.infer<typeof setUsernameParams>;

export const setUsernameRequest = authedServiceRequest.merge(
  z.object({
    params: setUsernameParams,
  }),
);

export const setUsernameResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type SetUsernameRequest = z.infer<typeof setUsernameRequest>;
export type SetUsernameResponse = z.infer<typeof setUsernameResponse>;

/** ******************************************************************************
 *  Get Username
 ******************************************************************************* */

export const getUsernameParams = z.object({
  userId: z.string(),
});

export const getUsernameRequest = serviceRequest.merge(
  z.object({
    params: getUsernameParams,
  }),
);

export const getUsernameResponse = serviceResponse.merge(
  z.object({
    username: z.string().nullable(),
  }),
);

export type GetUsernameParams = z.infer<typeof getUsernameParams>;
export type GetUsernameRequest = z.infer<typeof getUsernameRequest>;
export type GetUsernameResponse = z.infer<typeof getUsernameResponse>;

/** ******************************************************************************
 *  Start Trial
 ******************************************************************************* */

export const startTrialParams = z.undefined();

export const startTrialRequest = authedServiceRequest.merge(
  z.object({
    params: startTrialParams,
  }),
);

export const startTrialResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type StartTrialParams = z.infer<typeof startTrialParams>;
export type StartTrialRequest = z.infer<typeof startTrialRequest>;
export type StartTrialResponse = z.infer<typeof startTrialResponse>;

/** ******************************************************************************
 *  Create Stripe Session
 ******************************************************************************* */

export const createStripeSessionParams = z.undefined();

export const createStripeSessionRequest = authedServiceRequest.merge(
  z.object({
    params: createStripeSessionParams,
  }),
);

export const createStripeSessionResponse = serviceResponse.merge(
  z.object({
    sessionUrl: z.string().nullable().optional(),
  }),
);

export type CreateStripeSessionParams = z.infer<
  typeof createStripeSessionParams
>;
export type CreateStripeSessionRequest = z.infer<
  typeof createStripeSessionRequest
>;
export type CreateStripeSessionResponse = z.infer<
  typeof createStripeSessionResponse
>;

/** ******************************************************************************
 *  Super List Users
 ******************************************************************************* */

export const superListUsersParams = z.object({
  _id: z.string().optional(),
  email: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  cursor: z
    .object({
      pageSize: z.number(),
      offset: z.number(),
    })
    .nullish(),
});

export const superListUsersRequest = authedServiceRequest.merge(
  z.object({
    params: superListUsersParams,
  }),
);

export const superListUsersResponse = serviceResponse.merge(
  z.object({
    users: z.array(UserMetricsSchema).optional(),
    total: z.number().optional(),
  }),
);

export type SuperListUsersParams = z.infer<typeof superListUsersParams>;
export type SuperListUsersRequest = z.infer<typeof superListUsersRequest>;
export type SuperListUsersResponse = z.infer<typeof superListUsersResponse>;

/** ******************************************************************************
 *  Super Impersonate User
 ******************************************************************************* */

export const superImpersonateUserParams = z.object({
  userId: z.string(),
});

export const superImpersonateUserRequest = authedServiceRequest.merge(
  z.object({
    params: superImpersonateUserParams,
  }),
);

export const superImpersonateUserResponse = authenticationResponse;

export type SuperImpersonateUserParams = z.infer<
  typeof superImpersonateUserParams
>;
export type SuperImpersonateUserRequest = z.infer<
  typeof superImpersonateUserRequest
>;
export type SuperImpersonateUserResponse = z.infer<
  typeof superImpersonateUserResponse
>;

/** ******************************************************************************
 *  System User
 ******************************************************************************* */

export const systemUserResponse = z.object({
  user: UserSchema,
  auth: authData,
});

export type SystemUserResponse = z.infer<typeof systemUserResponse>;

/** ******************************************************************************
 *  OAuth
 ******************************************************************************* */

export const zAuthenticationStrategy = z.enum([
  "BasicRegister",
  "BasicLogin",
  "Google",
  "Discord",
  "Github",
  "LinkedIn",
]);

export type AuthenticationStrategy = z.infer<typeof zAuthenticationStrategy>;

// export default AuthenticationStrategy;

/** ******************************************************************************
 *  OAuth Login User
 ******************************************************************************* */

export const oAuthloginUserParams = z.object({
  email: z.string().email(),
});

export const oAuthloginUserRequest = serviceRequest.merge(
  z.object({
    params: oAuthloginUserParams,
  }),
);

export const oAuthloginUserResponse = authenticationResponse;

export type OAuthLoginUserParams = z.infer<typeof oAuthloginUserParams>;
export type OAuthLoginUserRequest = z.infer<typeof oAuthloginUserRequest>;
export type OAuthLoginUserResponse = z.infer<typeof oAuthloginUserResponse>;

/** ******************************************************************************
 *  Get User By Email
 ******************************************************************************* */

export const getUserByEmailParams = z.object({
  userEmail: z.string(),
});

export const getUserByEmailRequest = serviceRequest.merge(
  z.object({
    params: getUserByEmailParams,
  }),
);

export const getUserByEmailResponse = serviceResponse.merge(
  z.object({
    user: UserSchema.nullable().optional(),
  }),
);

export type GetUserByEmailParams = z.infer<typeof getUserByEmailParams>;
export type GetUserByEmailRequest = z.infer<typeof getUserByEmailRequest>;
export type GetUserByEmailResponse = z.infer<typeof getUserByEmailResponse>;
