import { atom } from "jotai";
import { atomFamily, atomWithDefault } from "jotai/utils";

import type {
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";
import { CharacterVisibilitySchema } from "@charry/models";

import { trpc } from "~/lib/trpc";

const createCharacterParams = atomWithDefault<CreateCharacterParams>((get) => {
  return get(
    atom<CreateCharacterParams>({
      visibility: CharacterVisibilitySchema.Enum.Public,
      name: "",
      title: "",
      copyable: true,
      greeting: "",
      description: "",
      definition: "",
      imageUrl: "",
      category: "Other",
      tags: [],
    }),
  );
});

const updateCharacterParams = atomFamily((characterId: string) => {
  const characterState: UpdateCharacterParams = {
    id: characterId,
    visibility: CharacterVisibilitySchema.Enum.Public,
    name: "",
    title: "",
    copyable: true,
    greeting: "",
    description: "",
    definition: "",
    imageUrl: "",
    tags: [],
  };

  return atomWithDefault<UpdateCharacterParams>(async () => {
    try {
      const { characters } = await trpc.character.explore.query();
      const character = characters?.find((c) => c.id === characterId);

      if (!character) {
        return characterState;
      }

      return character;
    } catch (e) {
      return characterState;
    }
  });
});

export const CharacterState = {
  createCharacterParams,
  updateCharacterParams,
};
