import React, { useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import type { AuthenticationStrategy, LoginUserParams } from "@charry/models";
import { zAuthenticationStrategy } from "@charry/models";

import GithubLogo from "~/assets/github-button.png";
import GoogleLogo from "~/assets/google-button.png";
import PosthogEvent from "~/constants/PosthogEvent.constants";
import AuthUtil from "~/lib/auth";
import PasswordInput from "~/ui/components/PasswordInput";
import Input from "~/ui/elements/Input";
import { withSuspense } from "~/ui/hoc/withSuspense";
import useTracking from "~/ui/hooks/useTracking.hook";
import useUser from "~/ui/hooks/useUser.hook";
import MaintenanceModeLayout from "~/ui/layouts/MaintenanceMode.layout";
import useIsAuthenticated from "../ui/hooks/useIsAuthenticated.hook";
import useLoginSuccessRedirect from "../ui/hooks/useLoginSuccessRedirect.hook";

const LoginPage = () => {
  const navigate = useNavigate();
  const { loginSuccessRedirect } = useLoginSuccessRedirect();
  const { login, authenticate } = useUser();
  const { isAuthenticated } = useIsAuthenticated();
  const toast = useToast();
  const [params, _setParams] = React.useState<LoginUserParams>({
    email: "",
    password: "",
  });

  const [errors, setErrors] = React.useState<Record<string, object | null>>({});
  const { track } = useTracking();

  const setParams = (key: keyof LoginUserParams, value: string) => {
    _setParams((prev) => ({ ...prev, [key]: value }));
    setErrors((prev) => ({ ...prev, [key]: null }));
  };

  const loginUser = useCallback(
    async (strategy?: AuthenticationStrategy) => {
      try {
        let result;
        if (strategy) {
          result = await AuthUtil.startOAuthFlow(strategy);
        } else {
          result = await login.mutateAsync(params);
        }

        const { user, token, error } = result;

        /**
         * OAuth can return an error here, which we throw
         * and catch below to pop a toast
         */
        if (error) {
          throw error;
        }

        if (!token || !user) {
          throw new Error("Failed to login");
        }

        track({
          event: PosthogEvent.AuthLogin,
        });

        /**
         * Set the params to the user's email and password
         * so they appear in the form while the redirect is loading
         */
        _setParams({
          email: user.email,
          password: params.password ? params.password : user.email,
        });

        /**
         * Set token and initialize user
         */
        authenticate({ user, token });

        if (user.emailVerificationRequired && user.emailVerifiedAt == null) {
          navigate("/verify-email");
          return;
        }

        await loginSuccessRedirect();
      } catch (e1: unknown) {
        try {
          // @ts-expect-error - types need improvement
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const json = JSON.parse(e1.message);
          setErrors(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            json.reduce(
              (cur: Record<string, object>, next: unknown) => ({
                ...cur,
                // @ts-expect-error - types need improvement
                [next.path]: next,
              }),
              {},
            ),
          );
          toast({
            title: "Invalid Fields",
            description:
              "Some of the fields above are invalid. Please correct the errors and try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } catch (e2) {
          toast({
            title: "Error Logging In",
            description: (e1 as Error).message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    },
    [authenticate, login, loginSuccessRedirect, navigate, params, toast, track],
  );

  useEffect(() => {
    if (isAuthenticated) {
      void loginSuccessRedirect();
    }
  }, [isAuthenticated, loginSuccessRedirect]);

  return (
    <MaintenanceModeLayout>
      <Box
        backgroundColor="gray.1000"
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
      >
        <Flex
          position="relative"
          top={{ base: "0", md: "96px" }}
          width={{ base: "100%", md: "420px" }}
          height="fit-content"
          flexDirection="column"
          alignItems="center"
          borderRadius="8px"
          boxSizing="border-box"
        >
          <Link to="/"></Link>
          <Box height="24px" />
          <Text variant="30-bold" color="white" marginLeft="16px">
            Log in to your account
          </Text>
          <Box height="12px" />
          <Text variant="16-reg" color="gray.400" marginLeft="16px">
            Welcome back! Please enter your details.
          </Text>
          <Box height="32px" />
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Stack width="100%" spacing="20px">
              <Input
                id="email"
                isDisabled={login.isPending}
                label="Email"
                type="email"
                width="100%"
                size="lg"
                value={params.email}
                placeholder="Enter your email"
                onChange={(e) => setParams("email", e.currentTarget.value)}
                isInvalid={errors.email ? true : false}
                autoFocus
              />
              <PasswordInput
                id="password"
                isDisabled={login.isPending}
                label="Password"
                width="100%"
                size="lg"
                value={params.password}
                placeholder="••••••••"
                onChange={(e) => setParams("password", e.currentTarget.value)}
                isInvalid={errors.password ? true : false}
              />
            </Stack>
            <Stack width="100%" spacing="0">
              <Box height="24px" />
              {login.isPending ? (
                <Flex alignItems="center" justify="center" height="104px">
                  <Spinner size="lg" />
                </Flex>
              ) : (
                <Flex flexDir={"column"} gridGap={"24px"}>
                  <Button
                    variant="primary"
                    size="lg"
                    width="100%"
                    onClick={() => loginUser()}
                    // Not sure why login.isLoading is not a boolean here.

                    isLoading={login.isPending}
                    type="submit"
                  >
                    Sign in
                  </Button>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Flex>
                      <Checkbox />
                      <Text variant="14-semi" color="white" marginLeft="8px">
                        Remember for 30 days
                      </Text>
                    </Flex>
                    <Flex>
                      <Link to="/forgot-password">
                        <Text variant="14-bold" color="#2970FF">
                          Forgot password
                        </Text>
                      </Link>
                    </Flex>
                  </Flex>
                  <Flex alignItems="center">
                    <Box
                      onClick={() =>
                        loginUser(zAuthenticationStrategy.Enum.Google)
                      }
                      _hover={{ cursor: "pointer" }}
                      margin="0 auto"
                    >
                      <Image
                        alt="Google Logo"
                        src={GoogleLogo}
                        width="100%"
                        height="46"
                      />
                    </Box>
                    <Box
                      onClick={() =>
                        loginUser(zAuthenticationStrategy.Enum.Github)
                      }
                      _hover={{ cursor: "pointer" }}
                      margin="0 auto"
                    >
                      <Image
                        alt="Github Logo"
                        src={GithubLogo}
                        width="100%"
                        height="46"
                      />
                    </Box>
                  </Flex>

                  <Text variant="14-reg" color="white">
                    Don&apos;t have an account?{" "}
                    <Link to={"/register"}>
                      <Text as="span" variant="14-bold" color="#2970FF">
                        Sign up
                      </Text>
                    </Link>
                  </Text>
                </Flex>
              )}
            </Stack>
          </form>
        </Flex>
      </Box>
    </MaintenanceModeLayout>
  );
};

export default withSuspense(LoginPage);
