import type { AvatarProps } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { Avatar } from "@chakra-ui/react";

import type { Character } from "@charry/models";

type CharacterAvatarProps = {
  characterName?: string;
  characterImageUrl?: string;
  character?: Character | null;
  onClick?: () => void;
  size?: string;
} & AvatarProps;

const CharacterAvatar: React.FC<CharacterAvatarProps> = ({
  character,
  characterName,
  characterImageUrl,
  onClick,
  size = "md",
  ...rest
}) => {
  const nameToUse = useMemo(() => {
    if (characterName) {
      return characterName;
    }

    return character?.name.split(" ")[0] ?? "";
  }, [character, characterName]);

  const imageUrl = useMemo(() => {
    if (characterImageUrl) {
      return characterImageUrl;
    }

    return character?.imageUrl ?? "";
  }, [character, characterImageUrl]);

  return (
    <Avatar
      name={nameToUse}
      src={imageUrl}
      onClick={onClick}
      backgroundColor="purple.300"
      color="black"
      size={size}
      {...rest}
    />
  );
};

export default CharacterAvatar;
