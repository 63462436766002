import * as React from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  FiAlertCircle,
  FiAperture,
  FiChevronLeft,
  FiChevronRight,
  FiHome,
  FiLogIn,
  FiMessageSquare,
  FiPlus,
  FiSettings,
  FiTwitter,
  FiUser,
} from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";

import type { User } from "@charry/models";
import { isSuperUser } from "@charry/utils";

import CharryLogo from "~/assets/charry.svg";
// import TwitterLogo from "~/assets/twitter.svg";
import { DISCORD_INVITE_LINK, TWITTER_LINK } from "~/lib/const";
import useCharacterList from "~/ui/hooks/useCharacterList.hook";
import useChat from "~/ui/hooks/useChat.hook";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useUser from "~/ui/hooks/useUser.hook";
import AuthMenu from "../AuthMenu";
import LoginRegisterModal from "./LoginRegisterModal";
import { NavButton } from "./NavButton";
import RecentChatNavButton from "./RecentChatNavButton";

const OrganizationDetails = ({ user }: { user?: User }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        border="1px solid"
        borderColor="gray.800"
        padding="8px 12px"
        borderRadius="50%"
        width="40px"
        height="40px"
        position="relative"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
          borderRadius="50%"
          color="white"
          fontWeight="bold"
          fontSize="16px"
        >
          {user?.username?.charAt(0)}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid"
      borderColor="gray.800"
      padding="8px 12px"
      borderRadius="8px"
    >
      <Box display="flex" alignItems="center">
        <Link to="/">
          <Image
            alt="Charry Logo"
            src={CharryLogo}
            width="20"
            height="20"
            style={{
              maxWidth: "20px",
              height: "20px",
            }}
          />
        </Link>
        <Box width="8px" />
        <Text
          variant="14-bold"
          color="white"
          maxWidth="128px"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {user?.username}
          {/* {user?.lastName} */}
        </Text>
      </Box>
      <ChevronDownIcon color="gray.600" fontSize="24px" />
    </Box>
  );
};

const SideNavigation: React.FC = () => {
  const isMobile = useIsMobile();
  const { user, isAuthenticated } = useUser();
  const navigate = useNavigate();
  const [isCollapsed, _setIsCollapsed] = React.useState(
    useBreakpointValue({ base: false, md: true }),
  );

  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleNavClick = (path: string) => {
    if (isAuthenticated) {
      navigate(path);
    } else {
      onOpen();
    }
  };

  const { characterList } = useCharacterList();

  const { chatList } = useChat();

  const ToggleButton = ({
    onClick,
    isCollapsed,
  }: {
    onClick: () => void;
    isCollapsed: boolean;
  }) => (
    <Button
      position="absolute"
      bottom="100px"
      left="10px"
      zIndex="5"
      size="sm"
      padding="0px"
      onClick={onClick}
      aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
      variant="outline"
      borderRadius="full"
      bg="gray.1100"
    >
      {isCollapsed ? <FiChevronRight /> : <FiChevronLeft />}
    </Button>
  );

  const recentChats = React.useMemo(() => {
    if (!isAuthenticated) return [];

    const uniqueChats = chatList.reduce(
      (acc, chat) => {
        const character = characterList.find((c) => c.id === chat.characterId);
        if (!character) return acc;

        const existingChat = acc.find(
          (c) => c.characterName === character.name,
        );
        if (!existingChat) {
          acc.push({
            id: chat.id,
            name:
              chat.turns[chat.turns.length - 1]?.content?.slice(0, 15) +
                "..." || "New Chat",
            characterId: chat.characterId,
            characterName: character.name,
            characterImageUrl: character.imageUrl ?? "",
          });
        }
        return acc;
      },
      [] as {
        id: string;
        name: string;
        characterId: string;
        characterName: string;
        characterImageUrl: string;
      }[],
    );

    return uniqueChats.slice(0, 5);
  }, [characterList, chatList, isAuthenticated]);

  return (
    <Flex
      as="section"
      height="100dvh"
      minHeight="-webkit-fill-available"
      bg="gray.1100"
      borderRight={isSidebarCollapsed ? "0px" : "1px solid"}
      borderColor="gray.800"
      width={isMobile ? (isSidebarCollapsed ? "0px" : "50px") : "240px"}
      flexShrink={0}
      padding={isMobile ? (isSidebarCollapsed ? "0px" : "5px") : "16px"}
      transition="width 0.3s"
    >
      {isMobile && (
        <ToggleButton
          onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
          isCollapsed={isSidebarCollapsed}
        />
      )}
      <Flex
        flex="1"
        direction="column"
        justifyContent="space-between"
        display={isMobile && isSidebarCollapsed ? "none" : "flex"}
      >
        <Box width="fill-available" flex="1" position="relative">
          {isMobile ? <Box height="8px" /> : null}
          {isAuthenticated ? (
            <AuthMenu>
              <OrganizationDetails user={user ?? undefined} />
            </AuthMenu>
          ) : (
            <>
              <Button
                variant="secondary"
                width="100%"
                size="sm"
                onClick={onOpen}
              >
                {isMobile ? <FiLogIn /> : "Login / Register"}
              </Button>
              <LoginRegisterModal isOpen={isOpen} onClose={onClose} />
            </>
          )}
          <Box height="24px" />
          {isAuthenticated ? (
            <Box flex="1">
              <Button
                variant="outline"
                width="100%"
                size="sm"
                onClick={() => handleNavClick("/characters/create")}
                mb={4}
              >
                {!isMobile ? (
                  <Flex alignItems="center">
                    <FiPlus />
                    &nbsp;Create
                  </Flex>
                ) : (
                  <FiPlus />
                )}
              </Button>
            </Box>
          ) : (
            <Box flex="1">
              <Button
                variant="outline"
                width="100%"
                size="sm"
                onClick={onOpen}
                mb={4}
              >
                {!isMobile ? (
                  <Flex alignItems="center">
                    <FiPlus />
                    &nbsp;Create
                  </Flex>
                ) : (
                  <FiPlus />
                )}
              </Button>
            </Box>
          )}
          <Box flex="1">
            <NavButton
              label="Explore"
              icon={FiHome}
              link="/"
              isCollapsed={isCollapsed}
            />

            <NavButton
              label="Chat History"
              icon={FiMessageSquare}
              isCollapsed={isCollapsed}
              onClick={() => handleNavClick("/recent")}
            />

            <NavButton
              label="My Characters"
              icon={FiAperture}
              isCollapsed={isCollapsed}
              onClick={() => handleNavClick("/characters")}
            />

            <NavButton
              label="Settings"
              icon={FiSettings}
              isCollapsed={isCollapsed}
              onClick={() => handleNavClick("/settings")}
            />

            {user != null && user.username == null && (
              <NavButton
                label="Set Username"
                icon={FiUser}
                link="/set-username"
                isCollapsed={isCollapsed}
              />
            )}

            {isSuperUser(user?.email ?? "") && (
              <NavButton
                label="Super Admin"
                icon={FiAlertCircle}
                link="/super/users"
                isActiveLink={(path) => path.includes("/super")}
                isCollapsed={isCollapsed}
              />
            )}

            {!isMobile && isAuthenticated && recentChats.length > 0 && (
              <>
                <Box height="24px" />
                <Text fontSize="sm" color="gray.500" mb={2} ml={2}>
                  Recent Chats
                </Text>
                {recentChats.map((chat) => (
                  <RecentChatNavButton
                    key={chat.id}
                    link={`/chat/${chat.id}`}
                    isCollapsed={isCollapsed ?? false}
                    characterName={chat.characterName}
                    characterImageUrl={chat.characterImageUrl}
                  />
                ))}
              </>
            )}

            {isMobile && isAuthenticated && recentChats.length > 0 && (
              <>
                <Box height="24px" />
                {recentChats.map((chat) => (
                  <RecentChatNavButton
                    key={chat.id}
                    link={`/chat/${chat.id}`}
                    isCollapsed={true}
                    characterName={chat.characterName}
                    characterImageUrl={chat.characterImageUrl}
                  />
                ))}
              </>
            )}

            {/* <NavButton
              label="Wallet"
              icon={FiDollarSign}
              link="/wallet"
              tag={!solanaWalletEnabledFlag ? "coming soon" : undefined}
              isCollapsed={isCollapsed}
            /> */}
            {/* <Box height="32px" />
            {user != null && user.discordId == null && (
              <NavButton
                label="Link Discord"
                icon={FiMessageSquare}
                image={DiscordLogo}
                onClick={handleLinkDiscord}
                isActiveLink={(_path) => false}
                isExternal
                isCollapsed={isCollapsed}
              />
            )}
            {user != null &&
              !user.emailVerificationRequired &&
              user.emailVerifiedAt == null && (
                <NavButton
                  label="Verify Email"
                  icon={FiMail}
                  link="/verify-email"
                  isCollapsed={isCollapsed}
                />
              )} */}
            <Box height="24px" />
            <Box position="absolute" bottom="0px">
              <NavButton
                label="Discord"
                icon={FiMessageSquare}
                link={DISCORD_INVITE_LINK}
                isCollapsed={isCollapsed}
              />
              <NavButton
                label="Twitter"
                icon={FiTwitter}
                link={TWITTER_LINK}
                isCollapsed={isCollapsed}
              />
              {/* <HelpButton
                text="View Documentation"
                Icon={FiFile}
                onClick={() => {
                  window.open(DOCUMENTATION_LINK, "_blank");
                }}
              /> */}
              {/* <DebugButton />
              {isTauri && (
                <HelpButton
                  text={CONSOLE_FULL_VERSION}
                  Icon={FiInfo}
                  onClick={() => {
                    window.open(DOWNLOAD_LINK, "_blank");
                  }}
                />
              )} */}
            </Box>
          </Box>
        </Box>
        <LoginRegisterModal isOpen={isOpen} onClose={onClose} />
      </Flex>
    </Flex>
  );
};

export default SideNavigation;
