import "./index.css";

import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { createStore } from "jotai";
import { Provider } from "jotai/react";
import posthog from "posthog-js";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import ReactTooltip from "react-tooltip";

import type { AppConfig } from "./lib/types";
import {
  NODE_ENV,
  POSTHOG_API_KEY,
  POSTHOG_API_URL,
  SENTRY_DSN,
  SENTRY_SAMPLING_RATE,
} from "~/lib/const";
import { TRPCReactProvider } from "./lib/trpc";
import AccountSettingsPage from "./pages/dashboard/AccountSettings.page";
import CharacterDetailsPage from "./pages/dashboard/characters/CharacterDetails.page";
import CharacterListPage from "./pages/dashboard/characters/CharacterList.page";
import CharacterSettingsPage from "./pages/dashboard/characters/CharacterSettings.page";
import CreateCharacterPage from "./pages/dashboard/characters/CreateCharacter.page";
import ChatPage from "./pages/dashboard/Chat.page";
import ExplorePage from "./pages/dashboard/Explore.page";
import RecentChatsPage from "./pages/dashboard/RecentChats.page";
import SuperUsersPage from "./pages/dashboard/super/SuperUsers.page";
import DisabledUserPage from "./pages/DisabledUser.page";
// import DownloadPage from "./pages/Download.page";
import ErrorPage from "./pages/Error.page";
import ForgotPasswordPage from "./pages/ForgotPassword.page";
import PrivacyPolicyPage from "./pages/legal/PrivacyPolicy.page";
import TermsPage from "./pages/legal/Terms.page";
import LoginPage from "./pages/Login.page";
import RegisterPage from "./pages/Register.page";
import ResetPasswordPage from "./pages/ResetPassword.page";
import SetUsernamePage from "./pages/SetUsername.page";
import VerifyEmailConfirmationPage from "./pages/VerifyEmailConfirmation.page";
import VerifyEmailPage from "./pages/VerifyEmailPage.page";
// import WelcomePage from "./pages/Welcome.page";
import GlobalErrorBoundary from "./ui/components/GlobalErrorBoundary";
import { ModalProvider } from "./ui/components/modal/Modal";
import { AppConfigContext } from "./ui/contexts/AppConfig.context";
import CharacterLayout from "./ui/layouts/Character.layout";
import DashboardLayout from "./ui/layouts/Dashboard.layout";
import SuperLayout from "./ui/layouts/Super.layout";
import SolanaContextProvider from "./ui/providers/SolanaContextProvider";
import { theme } from "./ui/style/theme";

const AppContainer = () => {
  const location = useLocation();
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  return <Outlet />;
};

const getRouter = (_config: AppConfig) => {
  return createBrowserRouter([
    {
      path: "/",
      element: <AppContainer />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <DashboardLayout />,
          children: [
            {
              index: true,
              element: <ExplorePage />,
            },
            {
              path: "/settings",
              element: <AccountSettingsPage />,
            },
            {
              path: "/characters",
              element: <CharacterListPage />,
            },
            {
              path: "/characters/create",
              element: <CreateCharacterPage />,
            },
            {
              path: "/characters",
              element: <CharacterLayout />,
              children: [
                {
                  path: "/characters/details/:characterId",
                  element: <CharacterDetailsPage />,
                },
                {
                  path: "/characters/settings/:characterId",
                  element: <CharacterSettingsPage />,
                },
              ],
            },
            {
              path: "/chat/:chatId",
              element: <ChatPage />,
            },
            {
              path: "/recent",
              element: <RecentChatsPage />,
            },
            {
              path: "/character/:characterId",
              element: <CharacterDetailsPage />,
            },
            {
              path: "/super",
              element: <SuperLayout />,
              children: [
                {
                  path: "/super/users",
                  element: <SuperUsersPage />,
                },
              ],
            },
          ],
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicyPage />,
        },
        {
          path: "/terms",
          element: <TermsPage />,
        },
        {
          path: "/set-username",
          element: <SetUsernamePage />,
        },
        // {
        //   path: "/download",
        //   element: <DownloadPage />,
        // },
        {
          path: "/login",
          element: <LoginPage />,
        },
        {
          path: "/register",
          element: <RegisterPage />,
        },
        {
          path: "/forgot-password",
          element: <ForgotPasswordPage />,
        },
        {
          path: "/reset-password",
          element: <ResetPasswordPage />,
        },
        {
          path: "/verify-email",
          element: <VerifyEmailPage />,
        },
        {
          path: "/verify-email-confirmation/:emailVerificationToken",
          element: <VerifyEmailConfirmationPage />,
        },
        // {
        //   path: "/welcome",
        //   element: <WelcomePage />,
        // },
        {
          path: "/paused",
          element: <DisabledUserPage />,
        },
        {
          path: "*",
          element: <Navigate to="/" replace />,
        },
      ],
    },
  ]);
};

/**
 * Export these because we need to use them in the Tauri app
 * to create a custom logger that passes errors as a Modal from the
 * worker to the main UI thread.
 */

export type { AppConfig };

export * from "~/ui/components/modal/Confirm.modal";
export * from "~/ui/components/modal/Modal";
export * from "~/ui/jotai/app.jotai";

export { api, trpc } from "~/lib/trpc";

export {
  CONSOLE_FULL_VERSION,
  CONSOLE_VERSION,
  CONSOLE_VERSION_HASH,
} from "~/lib/const";

export const jotaiStore = createStore();

export const App: React.FC<{ appConfig: AppConfig }> = ({ appConfig }) => {
  useEffect(() => {
    try {
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: NODE_ENV,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate: SENTRY_SAMPLING_RATE, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/*\.charry\.ai/],
        // Session Replay
        replaysSessionSampleRate: SENTRY_SAMPLING_RATE, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

        // "st" is short for "Sentry Tunnel"
        tunnel: "/st/",
      });
    } catch (error) {
      /* empty */
    }

    try {
      posthog.init(POSTHOG_API_KEY, {
        api_host: POSTHOG_API_URL,
        session_recording: {
          maskAllInputs: false,
        },
        autocapture: false,
        enable_recording_console_log: false,
      });
    } catch (error) {
      /* empty */
    }
  });

  return (
    <TRPCReactProvider>
      <AppConfigContext.Provider value={appConfig}>
        <SolanaContextProvider>
          <Provider store={jotaiStore}>
            <ChakraProvider theme={theme}>
              <GlobalErrorBoundary>
                <ModalProvider />
                <ReactTooltip />
                <RouterProvider router={getRouter(appConfig)} />
              </GlobalErrorBoundary>
            </ChakraProvider>
          </Provider>
        </SolanaContextProvider>
      </AppConfigContext.Provider>
    </TRPCReactProvider>
  );
};
