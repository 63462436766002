import React from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  FiMessageCircle,
  FiRepeat,
  FiShare2,
  FiThumbsUp,
} from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";

import CharacterAvatar from "~/ui/components/character/CharacterAvatar";
import ChatStarter from "~/ui/components/character/create/ChatStarter";
import RecentChatCard from "~/ui/components/chat/RecentChatCard";
import LoginRegisterModal from "~/ui/components/dashboard/LoginRegisterModal";
import useCharacter from "~/ui/hooks/useCharacter.hook";
import useChat from "~/ui/hooks/useChat.hook";
import { useChatStarters } from "~/ui/hooks/useChatStarters.hook";
import useIsMobile from "~/ui/hooks/useIsMobile.hook";
import useCharacterLike from "~/ui/hooks/useLike.hook";
import useUser from "~/ui/hooks/useUser.hook";
import { useUsername } from "~/ui/hooks/useUsername.hook";
import { Content, Header, Title } from "~/ui/layouts/Page.layout";
import CharactersDetailContentSuspense from "../../../ui/suspense/CharactersDetailContent.suspense";

const CharacterDetailsPageComponent: React.FC = () => {
  const isMobile = useIsMobile();
  const { characterId = "" } = useParams();
  const { character } = useCharacter(characterId);

  const { isAuthenticated } = useUser();
  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);
  const {
    isLiked,
    likesCount,
    toggleLike,
    isLoading: isLikeLoading,
  } = useCharacterLike(characterId);
  const { createChat, chatList } = useChat();
  const navigate = useNavigate();
  const { data: username } = useUsername(character?.userId ?? "");
  const toast = useToast();
  const characterChats = chatList.filter(
    (chat: any) => chat.characterId === characterId,
  );
  const chatStarters = useChatStarters(character);

  if (!characterId || characterId.trim() === "") {
    return <Text>Invalid character ID</Text>;
  }

  if (!character) {
    return <Text>Character not found</Text>;
  }

  const isPublic = character.visibility === "Public";
  const isUnlisted = character.visibility === "Unlisted";
  // const isPrivate = character.visibility === "Private";

  const handleNewChat = async () => {
    if (!isAuthenticated) {
      setIsLoginModalOpen(true);
      return;
    }
    try {
      const response = await createChat.mutateAsync({
        characterId: characterId || "",
      });
      if (response.chat) {
        navigate(`/chat/${response.chat.id}`);
      }
    } catch (error) {
      console.error("Failed to create new chat:", error);
    }
  };

  const handleChatStarter = async (starter: string) => {
    try {
      const response = await createChat.mutateAsync({
        characterId: character.id,
      });
      if (response.chat) {
        navigate(
          `/chat/${response.chat.id}?icebreaker=${encodeURIComponent(starter)}`,
        );
      }
    } catch (error) {
      console.error("Failed to create new chat:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleContinueChat = () => {
    if (characterChats.length > 0) {
      const sortedChats = [...characterChats].sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
      );
      if (sortedChats.length > 0) {
        navigate(`/chat/${sortedChats[0]?.id}`);
      }
    }
  };

  const handleShare = async () => {
    const shareUrl = `${window.location.origin}/character/${characterId}`;
    const shareTitle = `Check out ${character.name} on Charry!`;
    const shareText = `I found this interesting character on Charry: ${character.name}`;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (navigator.share) {
      try {
        await navigator.share({
          title: shareTitle,
          text: shareText,
          url: shareUrl,
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      // Fallback to copying the link
      await navigator.clipboard.writeText(shareUrl);
      toast({
        title: "Link copied to clipboard",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Header>
        <Title>{character.name}</Title>
      </Header>
      {isPublic || isUnlisted ? (
        <Content mt="12">
          <Grid templateColumns={isMobile ? "1fr" : "300px 1fr"} gap={6}>
            <GridItem>
              <VStack spacing={4} align="center">
                <CharacterAvatar character={character} size="2xl" />
                <Text fontSize="2xl" fontWeight="bold">
                  {character.name}
                </Text>
                <Text color="gray.500">
                  By @{username ? username.username : character.userId}
                </Text>
                <Text color="gray.500">
                  {character.category ? character.category : "No category"}
                </Text>
                <HStack spacing={12} width="100%" justifyContent="center">
                  <VStack>
                    <Box
                      as="button"
                      onClick={() => {
                        if (isAuthenticated) {
                          toggleLike();
                        } else {
                          setIsLoginModalOpen(true);
                        }
                      }}
                      disabled={isLikeLoading}
                      aria-label={isLiked ? "Unlike" : "Like"}
                    >
                      <FiThumbsUp
                        color={isLiked ? "green.500" : "gray.400"}
                        fill={isLiked ? "currentColor" : "none"}
                        size="24px"
                      />
                    </Box>
                    <Text color="gray.400" fontSize="sm">
                      {likesCount} Likes
                    </Text>
                  </VStack>

                  <VStack>
                    <FiMessageCircle size="24px" />
                    <Text color="gray.400" fontSize="sm">
                      {character.turnCount || 0} Chats
                    </Text>
                  </VStack>

                  <VStack>
                    <Box as="button" onClick={handleShare} aria-label="Share">
                      <FiShare2 size="24px" color="gray.400" />
                    </Box>
                    <Text color="gray.400" fontSize="sm">
                      Share
                    </Text>
                  </VStack>
                </HStack>
                {/* <ChakraLink
                as={RouterLink}
                to={`/chat/${characterId}`}
                width="100%"
              >
                <Button variant="primary" size="lg" width="100%">
                  Chat
                </Button>
              </ChakraLink> */}

                <Button
                  variant="primary"
                  size="lg"
                  width="100%"
                  leftIcon={<FiMessageCircle />}
                  onClick={handleNewChat}
                  isLoading={createChat.isPending}
                >
                  New Chat
                </Button>
                {characterChats.length > 0 && (
                  <Button
                    variant="outline"
                    size="lg"
                    width="100%"
                    leftIcon={<FiRepeat />}
                    onClick={handleContinueChat}
                  >
                    Continue Chat
                  </Button>
                )}
                {/* <Text fontSize="sm" fontWeight="bold">
                Categories
              </Text>
              <Flex wrap="wrap" gap={2}>
                {character.categories?.map((category, index) => (
                  <Text
                    key={index}
                    bg="gray.700"
                    px={2}
                    py={1}
                    borderRadius="md"
                  >
                    {category}
                  </Text>
                ))}
              </Flex> */}
              </VStack>
            </GridItem>
            <GridItem>
              <Tabs>
                <TabList>
                  <Tab>About</Tab>
                  <Tab>Chat History</Tab>
                  <Tab>Chat Starters</Tab>
                  {/* <Tab>Similar Characters</Tab> */}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <VStack align="stretch" spacing={4}>
                      <Box>
                        <Text fontWeight="bold">About {character.name}</Text>
                        <Text>
                          {character.description
                            ? character.description
                            : "No description"}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontWeight="bold">
                          {character.name}'s Greeting
                        </Text>
                        <Text>{character.greeting || "No greeting."}</Text>
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Where am I from?</Text>
                        <Text>
                          {character.title ? character.title : "Charry"}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Category</Text>
                        <Text>
                          {character.category
                            ? character.category
                            : "No category"}
                        </Text>
                      </Box>
                      <Box>
                        <Text fontWeight="bold">Tags</Text>
                        <Text>
                          {character.tags?.length
                            ? character.tags.join(", ")
                            : "No tags"}
                        </Text>
                      </Box>
                      {/* line break */}
                      <Divider />
                      <Box>
                        <VStack spacing={2}>
                          {chatStarters.map((starter, index) => (
                            <ChatStarter
                              key={index}
                              starter={starter}
                              onClick={handleChatStarter}
                            />
                          ))}
                        </VStack>
                      </Box>
                    </VStack>
                  </TabPanel>
                  <TabPanel>
                    {isAuthenticated ? (
                      characterChats.length > 0 ? (
                        <VStack align="stretch" spacing={4}>
                          {characterChats.map((chat) => (
                            <RecentChatCard
                              key={chat.id}
                              chat={chat}
                              character={character}
                            />
                          ))}
                        </VStack>
                      ) : (
                        <Text>No chat history with this character yet.</Text>
                      )
                    ) : (
                      <Text>Please log in to view chat history.</Text>
                    )}
                  </TabPanel>
                  <TabPanel>
                    <VStack align="stretch" spacing={4}>
                      {chatStarters.map((starter, index) => (
                        <ChatStarter
                          key={index}
                          starter={starter}
                          onClick={handleChatStarter}
                        />
                      ))}
                    </VStack>
                  </TabPanel>
                  <TabPanel>
                    <Text>
                      Similar characters feature not implemented yet...
                    </Text>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </GridItem>
          </Grid>
        </Content>
      ) : (
        <Flex direction="column" justify="center" align="center" h="50vh" p={1}>
          <CharacterAvatar character={character} size="2xl" />
          <Text textAlign="center" mb={4} mt={4}>
            This character is private, it is only visible to its author.
            <br />
            Create your own character to start chatting!
          </Text>
          {!isAuthenticated && (
            <Button
              onClick={() => setIsLoginModalOpen(true)}
              variant="primary"
              mt={4}
            >
              Login to create your own character
            </Button>
          )}
        </Flex>
      )}
      <LoginRegisterModal
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
      />
    </>
  );
};

export default function CharacterDetailsPage() {
  return (
    <React.Suspense fallback={<CharactersDetailContentSuspense />}>
      <CharacterDetailsPageComponent />
    </React.Suspense>
  );
}
