import React from "react";
import { Flex } from "@chakra-ui/react";

import type {
  CreateCharacterParams,
  UpdateCharacterParams,
} from "@charry/models";

import type useCharacter from "~/ui/hooks/useCharacter.hook";
import type useCharacterList from "~/ui/hooks/useCharacterList.hook";
import Textarea from "../../../elements/Textarea";

type CharacterDefinitionProps = {
  params: CreateCharacterParams | UpdateCharacterParams;
  setParams:
    | ReturnType<typeof useCharacterList>["setCreateCharacterParams"]
    | ReturnType<typeof useCharacter>["setUpdateCharacterParams"];
  errors: { definition?: string };
};

const CharacterDefinition: React.FC<CharacterDefinitionProps> = ({
  params,
  setParams,
  errors,
}) => {
  return (
    <Flex direction="column">
      <Textarea
        label="Backstory"
        size="lg"
        value={params.definition ?? ""}
        isInvalid={errors.definition ? true : false}
        placeholder="What is your character's backstory? How do you want it to talk or act?"
        onChange={(e) => {
          const definition = e.currentTarget.value;
          // @ts-expect-error - need better types here.
          void setParams((params) => {
            return {
              ...params,
              definition: definition,
            };
          });
        }}
      />
    </Flex>
  );
};

export default CharacterDefinition;
