import React, { useEffect } from "react";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";

import { AnalyticsEventKeys } from "../../../../constants/PosthogEvent.constants";
import { useTrack } from "../../../../hooks/analytics/useTrack";
import useCharacterList from "../../../hooks/useCharacterList.hook";
import CharacterCategory from "./CharacterCategory";
import CharacterDefinition from "./CharacterDefinition";
import CharacterDescription from "./CharacterDescription";
import CharacterGreeting from "./CharacterGreeting";
import CharacterImage from "./CharacterImage";
import CharacterName from "./CharacterName";
import CharacterTags from "./CharacterTags";
import CharacterTitle from "./CharacterTitle";
import CharacterVisibility from "./CharacterVisibility";
import CreateCharacterMenu from "./CreateCharacterMenu";

const CreateCharacter: React.FC = () => {
  const { createCharacterParams, setCreateCharacterParams } =
    useCharacterList();
  const [errors] = React.useState<Record<string, object>>({});
  const track = useTrack();

  useEffect(() => {
    track({
      name: AnalyticsEventKeys.CharacterCreation.ClickedCreateCharacter,
      properties: {},
    });
  }, [track]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Text variant="24-reg" color="white">
            Create a character
          </Text>
          <Box height="4px" />
          <Text variant="14-reg" color="gray.400">
            Fill out the fields below to get started
          </Text>
        </Box>
      </Box>
      <Box margin="24px 0px 32px">
        <Divider height="1px" backgroundColor="gray.800" />
      </Box>
      <CharacterName
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterImage
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterTitle
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterDescription
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterGreeting
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />

      <Flex height="24px" />
      <CharacterDefinition
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterVisibility
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterCategory
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />
      <Flex height="24px" />
      <CharacterTags
        params={createCharacterParams}
        setParams={setCreateCharacterParams}
        errors={errors}
      />

      <Box margin="24px 0px 32px">
        <Divider height="1px" backgroundColor="gray.800" />
      </Box>
      <CreateCharacterMenu />
      <Box height="300px" />
    </>
  );
};

export default CreateCharacter;
