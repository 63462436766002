import { z } from "zod";

import { CharShortSchema_CAI } from "./character.cai";
import { AvatarSchema_CAI } from "./other.cai";

export const UserSchema_CAI = z
  .object({
    characters: z.array(CharShortSchema_CAI),
    username: z.string(),
    name: z.string(),
    num_following: z.number().int(),
    num_followers: z.number().int(),
    avatar_file_name: z.string().nullable(),
    subscription_type: z.string(),
    bio: z.string().nullable(),
    creator_info: z.string().nullable(),
  })
  .merge(AvatarSchema_CAI);

export type User_CAI = z.infer<typeof UserSchema_CAI>;
