import { z } from "zod";

/**
 * These helper are for the UI code (web and tauri) which throw hard to
 * read Zod errors if environment variables fail to parse correctly or are
 * not provided.
 *
 * For the NodeJS/Bun environments the error messages are more clear and these
 * utils don't need to be used.
 */

type Environment = Record<string, string>;

export function getEnvStringFn(env: Environment) {
  return function (name: string, defaultValue?: string): string {
    const value = env[name];
    try {
      return z
        .string()
        .min(1)
        .parse(value ?? defaultValue);
    } catch (e) {
      throw new Error(
        `"${name}" is a required environment variable but was invalid or missing, received: '${value}'.`,
      );
    }
  };
}

export function getEnvStringAllowEmptyFn(env: Environment) {
  return function (name: string, defaultValue?: string): string {
    const value = env[name];
    try {
      return z.string().parse(value ?? defaultValue);
    } catch (e) {
      throw new Error(
        `"${name}" is a required environment variable but was invalid or missing, received: '${value}'.`,
      );
    }
  };
}

export function getEnvNumberFn(env: Environment) {
  return function (name: string, defaultValue?: number): number {
    const value = env[name];
    try {
      return z.number().parse(value ?? defaultValue);
    } catch (e) {
      throw new Error(
        `"${name}" is a required environment variable but was invalid or missing, received: '${value}'.`,
      );
    }
  };
}

export function getEnvBooleanFn(env: Environment) {
  return function (name: string, defaultValue?: boolean): boolean {
    const value = env[name];
    try {
      return z.boolean().parse(value ?? defaultValue);
    } catch (e) {
      throw new Error(
        `"${name}" is a required environment variable but was invalid or missing, received: '${value}'.`,
      );
    }
  };
}

const env = import.meta.env;

const getEnvString = getEnvStringFn(env);

export const NODE_ENV = getEnvString("VITE_NODE_ENV");
export const API_URL = getEnvString("VITE_API_URL");
export const VITE_APP_BASE_URL =
  "https://" +
  getEnvString("VITE_APP_BASE_URL", "charry.ai").replace("https://", "");
// export const VITE_APP_BASE_URL = "https://charry.ai"; // Hardcoded for prod fix
export const POSTHOG_API_KEY = getEnvString("VITE_POSTHOG_API_KEY");

export const POSTHOG_API_URL = getEnvStringAllowEmptyFn(env)(
  "VITE_POSTHOG_API_URL",
  "https://a.charry.ai",
);

export const SENTRY_DSN = getEnvString("VITE_SENTRY_DSN");
export const SENTRY_SAMPLING_RATE = getEnvNumberFn(env)(
  "VITE_SENTRY_SAMPLING_RATE",
  0.01,
);

export const DISCORD_INVITE_LINK = "https://discord.gg/charry";
export const TWITTER_LINK = "https://twitter.com/charry_ai";
export const TELEGRAM_LINK = "https://t.me/samhogan";
export const GITHUB_LINK = "https://github.com/context-labs";
export const MEETING_LINK = "https://cal.com/samhogan/15min";
export const DOCUMENTATION_LINK = "https://docs.kuzco.xyz";
export const DOWNLOAD_LINK = "https://kuzco.xyz/download";
export const CAREERS_LINK = "https://apply.workable.com/kuzco/";

export const LEARN_MORE_LINK = TWITTER_LINK;

export const MAX_USERNAME_LENGTH = 25;

// TODO: when releasing systemd service, update this to the new version
// export const CLI_INSTALL_SCRIPT = `sh -c "$(curl -sSL https://kuzco.cool/setup-kuzco.sh)"`;
export const CLI_INTERACTIVE_SETUP_SCRIPT = `sh -c "$(curl -sSL [KUZCO_BASE_URL]/setup-kuzco.sh)"`;
export const CLI_INSTALL_SCRIPT = `curl -fsSL [KUZCO_BASE_URL]/install.sh | sh`;
export const CLI_INIT_COMMAND = `kuzco init`;
export const CLI_LOGIN_COMMAND = `kuzco login`;

export const CONSOLE_VERSION = "0.0.5";

export const CONSOLE_VERSION_HASH = "dev";

export const CONSOLE_FULL_VERSION = `${CONSOLE_VERSION}-${CONSOLE_VERSION_HASH}`;
